/* THE FOUR STEPS TO BEING AN AWESOME UI ENGINEER (AND CO-CODER)
1. Try to keep custom styles to a minimum.
2. Be aware that these styles will affect your entire application.
3. Namespace your own custom styles with something
related to your app, for example, .spark-classname or .apollo-classname.
4. Use Canvas global variables for color, spacing and more.
They are CSS variables that are already globally available in the browser.
The full list is located here: https://canvas.prod.target.com/design-tokens. */

.praxcss-height100vh {
  height: 100vh;
}

.praxcss-avatarButton {
  border: 0;
  padding: 0;
  border-radius: var(--canvas-border-radius--expanded);
}

.layout-body-section {
  grid-column: 2/span 4 !important;
  margin: 4em 1.5em;
}

.home-app-cards, .home-relase-cards, .home-header-card {
  border: 1px solid var(--canvas-border-color--default-active);
}

// .home-app-cards {
//   min-height: 60vh;
// }

.home-header-card {
  background: var(--canvas-background-color--interactive-contrast-weak-hover);
}

.font-color-default {
  color: var(--canvas-font-color--default)
}

.font-color-secondary {
  color: var(--canvas-font-color--secondary)
}

.bg-default-contrast-weak {
  background: var(--canvas-background-color--default-contrast-weak)
}

.app-section-actionbar {
  display: flex;
  justify-content: space-between;
}

.app-description .app-name-display {
  font-weight: 500;
}

.font-weight-500 {
  font-weight: 500;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-700 {
  font-weight: 700;
}

.display-flex {
  display: flex;
}

.home-release-notes-app-names {
  font-weight: 500;
  background: #fff;
  text-align: center;
  padding: 5px 7px;
  border-radius: 3px;
}

.home-relase-cards .product-relase-launched-date, .release-content-modal .product-relase-launched-date {
  color: var(--canvas-font-color--disabled) !important;
}

.release-content-modal .C-Modal__container h1.C-Heading {
  margin-left: 10px;
}

.app-details-card {
  .info-training-button.C-Button:disabled {
    color: var(--canvas-font-color--disabled) !important;
    cursor: not-allowed;
    background: none !important;
    border-color: transparent !important;
  }
}
